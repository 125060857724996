import React, {useCallback, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import NewRecipeCard from "./NewRecipeCard";
import NoResults from "./NoResults";
import GetStarted from "./GetStarted";
import {updateSearchSettings} from "../../../redux/atlas/atlasSlice";
import {atlasSearchRecipes} from "../../../redux/atlas/atlasThunk";
import Loader from "../../../components/Loader";
import RecipeDetailModal from "../../../components/RecipeDetailModal";

export default function SampleExplore() {
    const recipes = useSelector((state) => state.atlas.recipes);
    const status = useSelector((state) => state.atlas.status);
    const pagination = useSelector((state) => state.atlas.pagination)
    const currentPage = useSelector(state => state.atlas.pageNum)

    const [openRecipeDetailModal, setOpenRecipeDetailModal] = useState(false);

    const dispatch = useDispatch()

    const observer = useRef()
    const lastRecipeElementRef = useCallback(node => {
        if (status === 'loading') return
        if (observer.current) observer.current?.disconnect()

        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && currentPage !== pagination.totalPages) {
                dispatch(updateSearchSettings({
                    page: currentPage + 1
                }))
                dispatch(atlasSearchRecipes())
            }
        })
        if (node) observer.current?.observe(node)
    }, [currentPage, status])


    if (status === "init") {
        return <GetStarted/>
    }

    if (recipes.length === 0 && status !== 'loading') {
        return <NoResults/>
    }

    return (
        <div
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-4 md:gap-6 lg:gap-8 p-2 pt-6 md:p-6 relative">
            {recipes.map((recipe, index) => {
                    if (recipes.length === index + 1) {
                        return <NewRecipeCard key={recipe._id} recipe={recipe} ref={lastRecipeElementRef} setOpenRecipeDetailModal={setOpenRecipeDetailModal}/>
                    } else {
                        return <NewRecipeCard key={recipe._id} recipe={recipe} setOpenRecipeDetailModal={setOpenRecipeDetailModal}/>
                    }
                }
            )}
            {
                status === 'loading' &&
                <div className={`absolute inset-x-0 -bottom-12 flex justify-center items-center h-screen}`}>
                    <Loader/>
                </div>
            }
            {status === 'failed' && <span>Error</span>}

            {openRecipeDetailModal && <RecipeDetailModal
                open={openRecipeDetailModal}
                setOpen={setOpenRecipeDetailModal}
            />}
        </div>
    );
}
